.swall-class {
  border-radius: 0 !important;

  @media (min-width: 1400px) {
    margin-top: 80px;
  }
}

.swal2-popup.swal2-toast {
  box-shadow: none !important;
  padding: 13px !important;
}

.swal2-container {
  padding: 0 !important;
}

pre {
  color: #f7f7f7;
  font-size: 14px;
  box-shadow: none;
  font-family: NeueHaasDisplayMediu, sans-serif;
  font-weight: 700;
}
