.icon {
  animation: notification 3s infinite;
}

@keyframes notification {
  5% {
    transform: rotate(30deg);
  }
  10% {
    transform: rotate(-30deg);
  }
  15% {
    transform: rotate(30deg);
  }
  20% {
    transform: rotate(0deg);
  }
}
